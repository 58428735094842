import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { logoBase64 } from "../../config/constant";
// Chakra imports
import {
    Box,
    Button,
    Flex,
    Grid,
    Icon,
    Spacer,
    Text,
    color,
    useColorModeValue,
    Divider,
    Spinner,
    Table,
    Tbody,
    Th,
    Tr,
    Thead,
  } from "@chakra-ui/react";

const GeneratePDFDelivery = (props) => {
  const generatePdfDocument = () => {
    const { name, items} = props; 
    let marginBerginItems = 115;
    const f = new Date();
    const doc = new jsPDF();
    
    const imageData = logoBase64; // Aquí va el dato de la imagen
    doc.addImage(imageData, 'JPEG', 30, 20, 60, 40); // Posición y tamaño de la imagen

    // Agregar texto al PDF
    doc.setFont('helvetica');
    doc.text('Recepción de material informático', 16, 80);
    doc.setFont('helvetica');
    doc.setFontSize(12);
    //doc.text('Fecha: '+ f.toLocaleDateString(), 16, 90);
    doc.text('Por medio de la presente, hago entrega del siguiente material electrónico:', 16, 100);
    
    //items.forEach((item, index) => {
      doc.setFontSize(12)
      //doc.text(` ${items.correlative} - ${items.type} - ${items.brand} - ${items.description}`, 20, marginBerginItems += 5)
      doc.text(`${items.code} - ${items.type} - ${items.brand} - ${items.description}`, 20, marginBerginItems += 5)
      doc.text(`Número de Serie ${items.serial_numner}`, 20, marginBerginItems += 5)
      if(items.product == "Telefono"){
        doc.text(`Memoria RAM: ${items.ram}, Disco Duro: ${items.hard_disc}`, 20, marginBerginItems += 5)
      }else if(items.product == "Sim"){
        doc.text(`Simcard `, 20, marginBerginItems += 5)
      }else if(items.product == "Perifericos"){
        doc.text(`Perifericos `, 20, marginBerginItems += 5)
      }else if(items.product == "Monitor"){
        doc.text(`Monitor `, 20, marginBerginItems += 5)
      }else{
        doc.text(`Procesador: ${items.processor}, Memoria RAM: ${items.ram}, Disco Duro: ${items.hard_disc}`, 20, marginBerginItems += 5)
      }
        
        //marginBerginItems += 5
    //});


    // Justificar texto usando autoTable
    const text = "Dicho material me fue entregado para su utilización en el proyecto y debo devolverlo en las mismas condiciones. No se debe abrir ni alterar bajo ninguna condición, de requerir algún cambio me debo comunicar con la Administración de Sistemas.";
    
    // Dividir el texto en líneas de una longitud máxima para simular justificación
    const splitText = doc.splitTextToSize(text, 180);
    const rows = splitText.map(line => [line]);

    marginBerginItems += 10

    // Agregar la tabla con el texto justificado al PDF
    doc.autoTable({
      body: rows,
      theme: 'plain', // Sin estilos predeterminados para que parezca texto simple
      styles: {
        fontSize: 12,
        cellPadding: 2,
        halign: 'justify'
      },
      margin: { top: marginBerginItems }
    });
    
    // Definir las columnas de la tabla
    const columns = ["Empleado/a", "Cliente", "Fecha de Entrega", "Firma"];
    // Definir las filas de la tabla
    const rowsTable = [
      [name, items.company, items.date_deli,''],
    ];

    // Agregar la tabla al PDF
    doc.autoTable({
        head: [columns],
        body: rowsTable,
        theme: 'plain', // Sin estilos predeterminados
        styles: {
            fontSize: 12,
            cellPadding: 0,
            textColor: [0, 0, 0], 
            lineColor: [0, 0, 0], 
            lineWidth: 0.1,
            cellPadding: 2,
            rowHeight: 20 // Altura de las filas
          },
        headStyles: {
            fontSize: 13,
            textColor: [0, 0, 0],
            fillColor: [255, 255, 255], 
            lineColor: [0, 0, 0], 
            lineWidth: 0.1,
            rowHeight: 1 // Altura de las filas
          },
        columnStyles: {
          0: { cellWidth: 50 }, // Primera columna de ancho 50
          1: { cellWidth: 50 }, // Segunda columna de ancho 100
          2: { cellWidth: 30 },  // Tercera columna de ancho 75
          3: { cellWidth: 50 },  // Tercera columna de ancho 75
        },
        startY: marginBerginItems + 30, 
        didDrawPage: (data) => {
            // Número de página y footer
            const pageCount = doc.internal.getNumberOfPages();
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
            const footerText1 = `IOON Technologies CIF B85816072 – http://www.ioon.es`;
            const footerText2 = `Calle Musgo 5, 28023, Madrid / Spain`;
            const footerText3 = `Tel. : (+34) 917 58 50 96`;
            const footerText4 = `© 2015.  IOON Technologies. Todos los derechos reservados.`;
            const footerTextWidth = doc.getTextWidth(footerText4);
            const centerX = (pageWidth - footerTextWidth) / 2;
    
            // Agregar el footer
            doc.setFontSize(8);
            doc.text(footerText1, data.settings.margin.left, pageHeight - 25);
            doc.text(footerText2, data.settings.margin.left, pageHeight - 20);
            doc.text(footerText3, data.settings.margin.left, pageHeight - 15);
            doc.text(footerText4, data.settings.margin.left, pageHeight - 10);

            const headerText = 'CONFIDENCIALIDAD : Nivel Básico';
            doc.setFontSize(10);
            doc.text(headerText, pageWidth - data.settings.margin.right - doc.getTextWidth(headerText), 10);
          }
        });

    
    // Guardar el PDF
    doc.save('HojaDeEntrega.pdf');
  };

  return (
    <div>
        <Button bg="red.500" color="white" fontSize="xs" p="8px 32px" mr="5" onClick={generatePdfDocument}>
            Descargar Archivo de Recepción
        </Button>
    </div>
  );
};

export default GeneratePDFDelivery;

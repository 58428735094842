import axios from "./index";

function getUserData() {
  const userDataProfile = JSON.parse(localStorage.getItem("user"));
  return userDataProfile;
}

class AuthApi {
  static Login = (data) => {
    return axios.post(`/login`, data);
  };

  static Logout = (data) => {
    return axios.post(`/users/logout`, data);
  };

  static getStatusByRequest = (id_request) => {
    const userData = getUserData();
    return axios.get(`/purchaseRequest/status/listtrack/${id_request}`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static getCountDashboard = () => {
    const userData = getUserData();
    return axios.get(`/dashboard/cards/count`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static getCountDashboardByProduct = (idProduct) => {
    const userData = getUserData();
    return axios.get(`/dashboard/cards/count/${idProduct}`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static getCountSoftware = () => {
    const userData = getUserData();
    return axios.get(`/dashboard/soft/count`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static getCountProducts = () => {
    const userData = getUserData();
    return axios.get(`/dashboard/product/count`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static getListRequest = () => {
    const userData = getUserData();
    return axios.get(`/purchaseRequest/list`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static getListPre = () => {
    const userData = getUserData();
    return axios.get(`/purchaseInitial/initial`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static changeStatusInitial = (data) => {
    const userData = getUserData();

    return axios.post(`/purchaseInitial/changeState`,
      { ...data, user_id: userData.id },
      {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      }
    );
  };

  static saveRequestInitial = (data) => {
    const userData = getUserData();

    return axios.post(`/purchaseInitial/store`,
      { ...data, user_id: userData.id },
      {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      }
    );
  };

  static getListRequestAll = () => {
    const userData = getUserData();
    return axios.get(`/purchaseRequest/list/all`, {
      headers: {
        'Authorization': `Bearer ${userData.token}`
      }
    });
  };

  static getListPendind = () => {
    const userData = getUserData();
    return axios.get(`/purchaseRequest/pending/${userData.id}`, {
      headers: {
        'Authorization': `Bearer ${userData.token}`
      }
    });
  };

  static getListRequestStatus = (id_status, id_product) => {
    const userData = getUserData();
    return axios.get(`/purchaseRequest/status/list/${id_status}/${id_product}`, {
      headers: {
        'Authorization': `Bearer ${userData.token}`
      }
    });
  };
  

  static getListUsers = () => {
    const userData = getUserData();
    return axios.get(`/users/list`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static getListRole = () => {
    const userData = getUserData();
    return axios.get(`/roles/list`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static getListTasks = () => {
    const userData = getUserData();
    return axios.get(`/tasks/list`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static getListType = () => {
    const userData = getUserData();
    return axios.get(`/typeRequest/list`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static getListProduct = (id_type) => {
    const userData = getUserData();
    return axios.get(`/products/list/${id_type}`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static getStricker = (id_product) => {
    const userData = getUserData();
    return axios.get(`/stickers/list/${id_product}`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static getRequest = (id_request) => {
    const userData = getUserData();
    return axios.get(`/purchaseRequest/getOne/${id_request}`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };



  static saveRequest = (data) => {
    const userData = getUserData();

    return axios.post(
      `/purchaseRequest/store`,
      { ...data, user_id: userData.id },
      {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      }
    );
  };

  static updateRequest = (data) => {
    const userData = getUserData();

    return axios.post(
      `/purchaseRequest/update`,
      { ...data, user_id: userData.id },
      {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      }
    );
  };
  

  static saveUser = (data) => {
    const userData = getUserData();

    return axios.post(
      `/users/register`,
      { ...data, user_id: userData.id },
      {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      }
    );
  };

  static approveRequest = (data) => {
    const userData = getUserData();

    return axios.post(
      `/purchaseRequest/approve`,
      { ...data, user_id: userData.id },
      {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      }
    );
  };

  static dateReturnFinal = (data) => {
    const userData = getUserData();

    return axios.post(`/purchaseRequest/datefinal`,
      { ...data, user_id: userData.id },
      {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      }
    );
  };
  

  static uploadFile = (data) => {
    const userData = getUserData();

    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("id", data.request_id);
    formData.append("user_id", userData.id);

    return axios.post(`/purchaseRequest/archive`, formData, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  };

  static getFilesRequest = (id_request) => {
    const userData = getUserData();
    return axios.get(`/purchaseRequest/archive/list/${id_request}`, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    });
  };

  static Register = (data) => {
    return axios.post(`${base}/register`, data);
  };
}

let base = "users";

export default AuthApi;

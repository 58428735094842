// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Spacer,
  Text,
  FormLabel,
  color,
  useColorModeValue,
  Divider,
  Spinner,
  Table,
  Tbody,
  Th,
  Tr,
  Thead,
} from "@chakra-ui/react";
import { AiOutlinePlus, AiOutlineDownload, AiOutlineUpload, AiOutlineCloseCircle, AiOutlineCheck } from 'react-icons/ai';
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { SearchIcon } from "@chakra-ui/icons";
import React, { Fragment, useState, useEffect } from "react"; 
import Modal from "react-modal";
import Swal from 'sweetalert2';
import TimelineStatus from "components/Tables/TimelineStatus";
import RowRequestDetail from "components/Tables/RowRequestDetail";
import InputText from "components/Form/InputText";
import InputDate from "components/Form/InputDate";
import InputNumber from "components/Form/InputNumber";
import InputAmount from "components/Form/InputAmount";
import InputNotBorder from "components/Form/InputNotBorder"; 
import InputTexarea from "components/Form/InputTexarea"; 
import InputFileButton from "components/Form/InputFileButton"; 
import Select , { components } from 'react-select';
import AuthApi from "../../api/auth";
import Functions from "../../functions/functions";
import { useParams, useLocation } from 'react-router-dom';
import GeneratePDFDelivery from './GeneratePDFDelivery';
import GeneratePDFReturn from './GeneratePDFReturn';


function PurchaseRequest() {
  const textColor = useColorModeValue("gray.700", "white");

  //const { id_purchase_request_1 } = useParams();
  const location = useLocation();
  const { id } = location.state || '';

  const [userDataProfile, setUserDataProfile] =  useState(JSON.parse(localStorage.getItem('user')))
  const [titlePage , setTitlePage] = useState('Buscar Solicitud')
  const [titleSectionRequest, setTitleSectionRequest] = useState('Nueva Solicitud')
  const [isLoadingRequestList, setIsLoadingRequestList] = useState(true);
  const [isLoadingRequestCurrent , setIsLoadingRequestCurrent] = useState(false);
  const [isLoadingSaveWait, setIsLoadingSaveWait] = useState(false);
  const [isLoadingProducs, setIsLoadingProducts] = useState(false);
  const [isLoadingCode, setIsLoadingCode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [isModalDeclineOpen, setIsModalDeclineOpen] = useState(false);
  const [isShowSelectRequest, setIsShowSelectRequest] = useState(true);
  const [isShowSelectProductRequest, setIsShowSelectProductRequest] = useState(true);
  const [isShowErrorForm, setIsShowErrorForm] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [showSelectedDate, setShowSelectedDate] = useState(false);
  const [showInputTexarea, setShowInputTexarea] = useState(true);

  const [messageTextModal, setMessageTextModal] = useState(null)
  const [titleTextModal, setTitleTextModal] = useState(null)
  const [buttonTextModal, setButtonTextModal] = useState(null)
  const [titleDateModal, setTitleDateModal] = useState(null)
  
  const [isSelectTypeDisabled, setIsSelectTypeDisabled] = useState(false);
  const [isSelectProductDisabled, setIsSelectProductDisabled] = useState(false);

  const [isShowInputRamRequest, setIsShowInputRamRequest] = useState(false);
  const [isShowInputProcesadorRequest, setIsShowInputProcesadorRequest] = useState(false);
  const [isShowInputHardDiscRequest, setIsShowInputHardDiscRequest] = useState(false);
  const [isShowInputWhitSIM, setIsShowInputWhitSIM] = useState(false);

  const [marginTopPageRequest, setMarginTopPageRequest] = useState(-60)

  const [errors, setErrors] = useState({ 
    //name_: false, 
    description_: false, 
    company_: false, 
    resoruce_: false, 
    //arrayDetail_: false,
    type_: false,
    product_ : false, 
    code_: false,
    price_: false,
    serialNumber_: false, 
    fileOrderBuy_: false,
    fileDelivery_: false,
    brand_: false,
    processor_: false,
    ram_ : false,
    hard_disc_: false,
    model_ : false
  });
  //const [errorsDetail, setErrorsDetail] = useState({ type_: false, product_ : false, code_: false, descriptionDetail_: false, price_: false, quantity_: false });
  const [errorsDecline, setErrorsDecline] = useState({ comment_: false, date_:false});

  const [listRequest, setListRequest] = useState([])
  const [listFiles, setListFiles] = useState([])
  const [selectedOptionSearch, setselectedOptionSearch] = useState('')
  const [listType, setListType] = useState([])
  const [selectedOptionType, setselectedOptionType] = useState('')
  const [listProduct, setListProduct] = useState([])
  const [selectedOptionProduct, setselectedOptionProduct] = useState('')
  
  const [idRequest, setIdRequest] = useState(0)
  const [statusRequest, setStatusRequest] = useState(null)
  const [nameRequest, setNameRequest] = useState(null)
  const [descriptionRequest, setDescriptionRequest] = useState(null)
  const [companyRequest, setCompanyRequest] = useState(null)
  const [resourceRequest, setResourceRequest] = useState(null)
  const [numerItemsRequest, setNumberItemsRequest] = useState(null)
  const [totalRequest, setTotalRequest] = useState(null)
  const [correlativeRequest, setCorrelativeRequest] = useState(null)
  const [commentDecline, setCommentDecline] = useState(null)
  const [existOrderBuy, setExistOrderBuy] = useState(false)
  const [existFileDelivery, setExistFileDelivery] = useState(false)
  const [placeHoderModel, setPlaceHolderModel] = useState('Ingrese modelo')

  const [arrayDetails, setArrayDetails] = useState([])
  const [dataDetail, setDataDetail] = useState([])
  const [codeRequestDetail, setCodeRequestDetail] = useState(null)
  const [codeRequestReserve, setCodeRequestReserve] = useState(null)
  const [descriptionRequestDetail, setDescriptionRequestDetail] = useState(null)
  const [priceRequestDetail, setPriceRequestDetail] = useState(0.00)
  const [totalRequestDetail, setTotalRequestDetail] = useState(null)
  const [quantityRequestDetail, setQuantityRequestDetail] = useState(null)
  const [itemRequestDetail, setItemRequestDetail] = useState(null)
  const [typeRequestDetail, setTypeRequestDetail] = useState(null)
  const [typeRequestDetailLabel, setTypeRequestDetailLabel] = useState(null)
  const [productRequestDetail, setProductRequestDetail] = useState(null)
  const [productRequestDetailLabel, setProductRequestDetailLabel] = useState(null)
  const [serialNumberRequestDetail, setSerialNumberRequestDetail] = useState(null)
  const [brandRequestDetail, setBrandRequestDetail] = useState(null)
  const [processorRequest, setProcessorRequest] = useState(null)
  const [ghzRequest, setGhzRequest] = useState(null)
  const [ramRequest, setRamRequest] = useState(null)
  const [hardDiscRequest, setHardDiscRequest] = useState(null)
  const [modelRequest, setModelRequest] = useState(null)
  const [isCheckedSim, setIsCheckedSim] = useState(false);
  const [codeRequestSim, setCodeRequestSim] = useState(null);

  const getListRequest = async () => {
    setIsLoadingRequestList(true)
    try {
      let response = await AuthApi.getListRequest();
      if (response.data.success === true) {
   
        let arrayData = [];
          response.data.data.forEach(element => {
            let item = {}
            /*
            if([8,9,10,11,12,13].includes(element.status_request_id)) {
              item = { value: element.id, label: element.correlative + " ("+ element.code +") " + " - " +  element.name_status};
            }else{
              item = { value: element.id, label: element.correlative + " - " +  element.name_status};
            }
            */
            //let codeRequest = ([8,9,10,11,12,13].includes(element.status_request_id))? element.code : ''
            item = { value: element.id, label: element.correlative + " ("+ element.code +") " + " - " +  element.name_status};
            
            arrayData.push(item);
          });
          setListRequest(arrayData)
          setIsLoadingRequestList(false)
      }
      
    } catch (err) {
      console.log(err);
    }
  };

  const getListType = async () => {
    try {
      let response = await AuthApi.getListType();
      if (response.data.success === true) {
        let arrayData = [];
          response.data.data.forEach(element => {
            let item = { value: element.id, label: element.name };
            arrayData.push(item);
          });
          setListType(arrayData)
      }
      
    } catch (err) {
      console.log(err);
    }
  };

  const getListProduct = async (id_type) => {
    try {
      let response = await AuthApi.getListProduct(id_type);
      if (response.data.success === true) {
        let arrayData = [];
          response.data.data.forEach(element => {
            let item = { value: element.id, label: element.name };
            arrayData.push(item);
          });
          setListProduct(arrayData)
          setIsLoadingProducts(false)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getStricker = async (id_product) => {
    try {
      let response = await AuthApi.getStricker(id_product);
      if (response.data.success === true) {
        //setCodeRequestDetail(response.data.data)
        setCodeRequestReserve(response.data.data)
        setIsLoadingCode(false)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getStrickerSim = async () => {
    try {
      let response = await AuthApi.getStricker(8);
      if (response.data.success === true) {
        //setCodeRequestDetail(response.data.data)
        setCodeRequestSim(response.data.data)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setDataRequest = (data, id_request_link) => {

    getListProduct(data.type_id);
    setIdRequest(data.id)
    setStatusRequest(data.status_request_id)
    //setNameRequest(data.name);
    setDescriptionRequest(data.description);

    //No muestra resource y client si estado es Disponible
    if(data.status_request_id != 8){
      setCompanyRequest(data.company);
      setResourceRequest(data.resource);
    }

    setSerialNumberRequestDetail(data.serial_numner)
    setBrandRequestDetail(data.brand)
    setProcessorRequest(data.processor)
    //setGhzRequest(data.ghz)
    setRamRequest(data.ram)
    setHardDiscRequest(data.hard_disc)
    setModelRequest(data.model)
    /*
    if(data.status_request_id == 8 && (data.company != '' || data.resource != '')){
      //Equipo Disponible, no debe mostrar que esta asigando
      
    }
    */
   console.log(data)
    setDataDetail(
      {
        //code : data.correlative + '(' + data.code + ')',
        code : data.code,
        correlative : data.correlative,
        type: data.type_name,
        product: data.product_name,
        description: data.description,
        brand: data.brand,
        company: data.company,
        serial_numner: data.serial_numner,
        hard_disc: data.hard_disc,
        ram: data.ram,
        processor: data.processor,
        model: data.model,
        date_deli: data.date_delivery,
        date_return: data.date_return,
      }
    )
    
    setCorrelativeRequest(data.correlative)
    setTitleSectionRequest(data.correlative)
    getFilesRequest(data.id)

    if([6,8,9,10,11,12,13].includes(data.status_request_id)) {
      setCodeRequestDetail(data.code)
    }

    setCodeRequestReserve(data.code)

    setPriceRequestDetail(data.total)
    setTotalRequestDetail(data.total)

    setselectedOptionType({ value: data.type_id, label: data.type_name })
    setTypeRequestDetail(data.type_id);
    setTypeRequestDetailLabel(data.type_name);

    setselectedOptionProduct({ value: data.product_id, label: data.product_name })
    setProductRequestDetail(data.product_id);
    setProductRequestDetailLabel(data.product_name);
    setIsSelectTypeDisabled(true);
    setIsSelectProductDisabled(true);

    if(data.product_name == 'Telefono'){
      setIsShowInputRamRequest(true);
      setIsShowInputProcesadorRequest(false);
      setIsShowInputHardDiscRequest(true);
    }else if(data.product_name == 'Portatil'){
      setIsShowInputRamRequest(true);
      setIsShowInputProcesadorRequest(true);
      setIsShowInputHardDiscRequest(true);
    }else{
      setIsShowInputRamRequest(false);
      setIsShowInputProcesadorRequest(false);
      setIsShowInputHardDiscRequest(false);
    }
    
    /*let arrayDetail_ = []; 
    
    (data.items).forEach(element => {

      let item = {
        'item' : element.item,
        'code' : element.code,
        'description' : element.name,
        'quantity' : element.quantity,
        'type' : '',
        //'product' : element.product_id,
        'price' : element.price,
        'total' : element.total
      };

      
      if((id_request_link == undefined) || (id_request_link == null)){
      //if(id == undefined){
        const typeLabel = Object.values(listType).find(item => item.value === element.type_id);
        item.type = typeLabel.label;
      }else{
        item.type = element.name_type;
      }
      
      arrayDetail_.push(item)
      
    });
    */
      
    //setArrayDetails(arrayDetail_);
    //setTotalPurchaseRequest(arrayDetail_)
    setIsLoadingRequestCurrent(false)
  }

  const setTotalPurchaseRequest = (array) => {
    let total = 0;
    array.forEach(element => {
      total = parseFloat(total) + parseFloat(element.total)
    });
      
    setTotalRequest(total.toFixed(2));
    setNumberItemsRequest(array.length)
  }

  const clearScreen = () => {
    getListRequest()
    setTitleSectionRequest("Nueva Solicitud")
    setselectedOptionSearch('');
    setStatusRequest(null);
    setIdRequest(0);
    setNameRequest('');
    setDescriptionRequest('');
    setCompanyRequest('');
    setResourceRequest('');
    setTotalRequest('');
    setArrayDetails([])
    setIsSelectTypeDisabled(false);
    setIsSelectProductDisabled(false);
    setselectedOptionType('')
    setselectedOptionProduct('')
    setSerialNumberRequestDetail('')
    setBrandRequestDetail('')
    setCodeRequestDetail('')
    setCodeRequestReserve('')
    setPriceRequestDetail(0)
    setModelRequest('')
    setIsShowInputWhitSIM(false);
    setCommentDecline('')
    setSelectedDate('')
    setListFiles([])
    setRamRequest('')
    setHardDiscRequest('')
    setPlaceHolderModel('Ingrese Modelo')
    setIsShowErrorForm(false);
  }

  const getRequest = async (idRequest_) => {
    try {
      let response = await AuthApi.getRequest(idRequest_);
      if (response.data.success === true) {
        
        setDataRequest(response.data.data, idRequest_)
      }
      
    } catch (err) {
      console.log(err);
    }
  };

  const getFilesRequest = async (idRequest_) => {
    try {
      let response = await AuthApi.getFilesRequest(idRequest_);
      let arrayFiles = [];
      if (response.data.success === true) {
        response.data.data.forEach(element => {
          //if (element.name.toLowerCase().includes("orden") && element.name.toLowerCase().includes("compra")) {
          if (element.name.toLowerCase().includes(".xlsx")) {
            // El nombre del archivo contiene "Orden" y "Compra"
            setExistOrderBuy(true)
          }
          if (element.name.toLowerCase().includes(".pdf")) {
            // El nombre del archivo contiene "Orden" y "Compra"
            setExistFileDelivery(true)
          }

          arrayFiles.push(element)
        });
        setListFiles(arrayFiles)
      }
      
    } catch (err) {
      console.log(err);
    }
  };
  
  useEffect(() => {
    getListType();
    getListProduct(1);
    getListRequest();

    const url = window.location;
    const parts = url.href.split('/');
    const valueIdRequessr = parts[parts.length - 1];

    let idPurchaseRequestURL = (id != undefined)? id : valueIdRequessr;
    
    //if(id != undefined){
    if(idPurchaseRequestURL != 'purchase-request'){
      
      setTitlePage('Solicitud')
      setIsShowSelectRequest(false)
      setIdRequest(parseInt(idPurchaseRequestURL));
      getRequest(parseInt(idPurchaseRequestURL));
      getFilesRequest(parseInt(idPurchaseRequestURL))
    }
    
  }, [])

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchIcon color="gray.400" />
      </components.DropdownIndicator>
    );
  };

  const handleChangeSelectRequest = (selectedOptionSearch) => {
    clearScreen()
    if(selectedOptionSearch != null){
      setselectedOptionSearch(selectedOptionSearch);
      setIdRequest(selectedOptionSearch.value);
      let correlative = (selectedOptionSearch.label).split("(")[0];
      setTitleSectionRequest(correlative)
      getRequest(selectedOptionSearch.value)
      setIsLoadingRequestCurrent(true)
      getFilesRequest(selectedOptionSearch.value)
    }else{
      clearScreen()
    }

  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: '#cbd5e0',
      color: '#cbd5e0',
      borderRadius: "15px",
      padding: '4px',
      marginBottom: "10px",
      fontSize: '14px',
    }),
  };

  const customStylesModal = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      borderRadius: '10px',
      border: 'none',
    }
  };

  const ulStyle = {
    listStyleType: 'none',
    padding: 0
  };

  const aStyle = {
    textDecoration: 'none',
    color: '#3498db',
    fontSize: '12px',
    transition: 'color 0.3s ease',
    cursor: 'pointer'
  };

  const customStylesSelectModal = (isInvalid) => ({
    control: (provided, state) => ({
      ...provided,
      color: '#cbd5e0',
      borderRadius: "15px",
      padding: '4px',
      marginBottom: "10px",
      fontSize: '14px',
      borderColor: isInvalid ? 'red' : '#cbd5e0',
      '&:hover': {
        borderColor: isInvalid ? 'red' : '#cbd5e0',
      },
    }),
  });

  const handleCaptureValueName = (value) => {
    setNameRequest(value);
  };
  const handleCaptureValueDescription = (value) => {
    setDescriptionRequest(value);
  };
  const handleCaptureValueCompny = (value) => {
    setCompanyRequest(value);
  };
  const handleCaptureValueResource = (value) => {
    setResourceRequest(value);
  };
  const handleCaptureValueTotal = (value) => {
    setTotalRequest(value);
  };
  const handleCaptureValueDetailCode = (value) => {
    setCodeRequestDetail(value);
  };
  const handleCaptureValueDetailDescription = (value) => {
    setDescriptionRequestDetail(value);
  };
  const handleCaptureValueDetailQuantity = (value) => {
    setQuantityRequestDetail(value);
  };
  const handleCaptureValueDetailPrice = (value) => {
    setPriceRequestDetail(value);
  };
  const handleCaptureValueDetailTotal = (value) => {
    setTotalRequestDetail(value);
  };
  const handleCaptureValueDetailType = (typeRequestDetail) => {

    getListProduct(typeRequestDetail.value);
    setIsShowSelectProductRequest(true)
    setIsLoadingProducts(true)
    setselectedOptionType(typeRequestDetail)
    setTypeRequestDetail(typeRequestDetail.value);
    setTypeRequestDetailLabel(typeRequestDetail.label);
    setIsShowSelectProductRequest(false)
  };

  const handleCaptureValueDetailBrand = (value) => {
    setBrandRequestDetail(value);
  };

  const handleCaptureValueDetailSerial= (value) => {
    setSerialNumberRequestDetail(value);
  };

  const handleCaptureValueProcessor= (value) => {
    setProcessorRequest(value);
  };
  const handleCaptureValueGhz = (value) => {
    setGhzRequest(value);
  };
  const handleCaptureValueRam = (value) => {
    setRamRequest(value);
  };

  const handleCaptureValueModel = (value) => {
    setModelRequest(value);
  };
  
  const handleCaptureValueHardDisc = (value) => {
    setHardDiscRequest(value);
  };

  const handleDateChange = (value) => {
    setSelectedDate(value);
  };

  const handleCheckboxChangeSim = () => {
    setIsCheckedSim(!isCheckedSim);
  };

  const handleCaptureValueDetailProduct = (productRequestDetail) => {
    setIsLoadingCode(true)

    getStricker(productRequestDetail.value);

    setselectedOptionProduct(productRequestDetail)
    setProductRequestDetail(productRequestDetail.value);
    setProductRequestDetailLabel(productRequestDetail.label);

    console.log("Se debe mostrar: ", productRequestDetail.label)
    if(productRequestDetail.label == 'Telefono'){
      setIsShowInputRamRequest(true);
      setIsShowInputProcesadorRequest(false);
      setIsShowInputHardDiscRequest(true);
      setIsShowInputWhitSIM(true);
      getStrickerSim()

      //Medidas del page
      setMarginTopPageRequest(-50)

    }else if(productRequestDetail.label == 'Portatil'){
      setIsShowInputRamRequest(true);
      setIsShowInputProcesadorRequest(true);
      setIsShowInputHardDiscRequest(true);
      setIsShowInputWhitSIM(false);

      //Medidas del page
      setMarginTopPageRequest(-50)

    }else if(productRequestDetail.label == 'Licencia Office'){
      setIsShowInputRamRequest(false);
      setIsShowInputProcesadorRequest(false);
      setIsShowInputHardDiscRequest(false);
      setIsShowInputWhitSIM(false);
      setBrandRequestDetail('Microsoft')
      setPlaceHolderModel('Teams Essentials')
    }else{
      setIsShowInputRamRequest(false);
      setIsShowInputProcesadorRequest(false);
      setIsShowInputHardDiscRequest(false);
      setIsShowInputWhitSIM(false);
    }
  }

  const handleCaptureValueCommentDecline = (value) => {
    setCommentDecline(value);
  };

  const handleCaptureValueFile = async (value) => {
    
    setIsLoadingSaveWait(true);
    
    let dataRequest = {
      request_id: idRequest,
      file: value[0]
    }

    try {
      let response = await AuthApi.uploadFile(dataRequest);
      if (response.data.success === true) {
        Swal.fire({
          icon: 'success',
          title: "Archivo Subido",
          text: "Se ha subido el archivo " + value[0].name + " de forma correcta a esta solicitud.",
          //position: 'top-end',
          showConfirmButton: false,   
          timer: 3000,
          timerProgressBar: true 
        });

        setIsLoadingSaveWait(false);
        getFilesRequest(idRequest)
        //clearScreen()
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addDetail = () => {
    setCodeRequestDetail('');
    setDescriptionRequestDetail('');
    setQuantityRequestDetail('');
    setPriceRequestDetail('');
    setTotalRequestDetail('');
    setTypeRequestDetail('');
    setProductRequestDetail('');
    setselectedOptionType('');
    setselectedOptionProduct('')
    setItemRequestDetail(null)

    setIsModalOpen(true); 
    setIsShowSelectProductRequest(true);

  }
  
  const saveDetail = () => {

    let newErrors = {};

    if(isShowSelectProductRequest == true){
      newErrors = {
        type_: !typeRequestDetail,
        product_: !productRequestDetail,
        //code_ : !codeRequestReserve,
        descriptionDetail_: !descriptionRequestDetail,
        //quantity_: !quantityRequestDetail,
        price_: !priceRequestDetail || parseFloat(priceRequestDetail) === 0.0

      };
    }else{
      newErrors = {
        type_: !typeRequestDetail,
        product_: !productRequestDetail,
        //code_ : !codeRequestReserve,
        descriptionDetail_: !descriptionRequestDetail,
        //quantity_: !quantityRequestDetail,
        price_: !priceRequestDetail || parseFloat(priceRequestDetail) === 0.0
      };
    }

    setErrorsDetail(newErrors);

    const formIsValid = Object.values(newErrors).every(error => !error);

    if (formIsValid) {
      setIsShowErrorForm(false);
      setIsModalOpen(false)

      let arrayDetail_ = arrayDetails;
      
      let item = {
        'item' : null,
        'code' : (typeRequestDetailLabel == 'Hardware')? codeRequestReserve : 'SOF',
        'description' :descriptionRequestDetail,
        //'quantity' : quantityRequestDetail,
        'quantity' : 1,
        'type' : typeRequestDetailLabel,
        'product' : (typeRequestDetailLabel == 'Hardware')? productRequestDetail : 10,   
        'price' : priceRequestDetail,
        //'total' : (parseFloat(quantityRequestDetail) * parseFloat(priceRequestDetail)).toFixed(2),
        'total' : (parseFloat(priceRequestDetail)).toFixed(2),
      };

      if(itemRequestDetail != null){
        item.item = itemRequestDetail;
        const detail_edit = Object.values(arrayDetails).find(item => item.item === itemRequestDetail);

        detail_edit.code = codeRequestDetail
        detail_edit.description = descriptionRequestDetail
        detail_edit.quantity = quantityRequestDetail
        detail_edit.type = typeRequestDetailLabel
        detail_edit.product = productRequestDetailLabel
        detail_edit.price = priceRequestDetail
        detail_edit.total = (parseFloat(quantityRequestDetail) * parseFloat(priceRequestDetail)).toFixed(2)

      }else{
        item.item = (arrayDetails.length == 0)? 1 : arrayDetails.length + 1;
        arrayDetail_.push(item)
      }

      setArrayDetails(arrayDetail_);

      setCodeRequestDetail('');
      setDescriptionRequestDetail('');
      setQuantityRequestDetail('');
      setPriceRequestDetail('');
      setTotalRequestDetail('');
      setTypeRequestDetail('');
      setProductRequestDetail('');
      setselectedOptionType('');
      setselectedOptionProduct('')
      setItemRequestDetail(null)

      setTotalPurchaseRequest(arrayDetails)
      setNumberItemsRequest(arrayDetails.length);
    } else {
      console.log("Hay errores en el formulario");
      setIsShowErrorForm(true);
    }
  };

  const handleEditDetail = (numberItem) => {

    setItemRequestDetail(numberItem);
    const detail_ = Object.values(arrayDetails).find(item => item.item === numberItem);
    const typeLabel_ = Object.values(listType).find(item => item.label === detail_.type);
    const product = Object.values(listProduct).find(item => item.value === detail_.product);

    //product
    if(typeLabel_.label == "Hardware"){
      setIsShowSelectProductRequest(true)
      setselectedOptionProduct(product)
      setProductRequestDetail(product.value);
      setProductRequestDetailLabel(product.label);
    }else{
      setIsShowSelectProductRequest(false)

    }

    setselectedOptionType(typeLabel_)
    setTypeRequestDetail(typeLabel_.value);
    setTypeRequestDetailLabel(typeLabel_.label);
    setCodeRequestDetail(detail_.code);
    setDescriptionRequestDetail(detail_.description);
    setQuantityRequestDetail(detail_.quantity);
    setPriceRequestDetail(detail_.price);
    
    setIsModalOpen(true)
  };

  const handleDownload = () => {
    console.log("hola")
    const filePath = './Files/Hojacompra.xlsx'; // Ruta del archivo en la carpeta public
    const link = document.createElement('a');
    link.href = filePath;
    link.download = 'Hojacompra.xlsx'; // Nombre con el que se descargará el archivo
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteDetail = (numberItem) => {
    const updatedDetails = Object.values(arrayDetails).filter(item => item.item !== numberItem);
    setArrayDetails(updatedDetails);
    setItemRequestDetail(null);
    setTotalPurchaseRequest(updatedDetails)
  };

  const saveRequest = async (type) => {

    setIsLoadingSaveWait(true);
    let newErrors = {};
    
    if(type == 'draft'){
      
      newErrors = {
        //name_: !nameRequest,
        //company_: !companyRequest,
        //resoruce_: !resourceRequest,
        //arrayDetail_ : (arrayDetails.length == 0)? true : false,
        type_: !typeRequestDetail,
        product_: !productRequestDetail,
        //code_ : !codeRequestReserve,
        //description_: !descriptionRequest,
        //descriptionDetail_: !descriptionRequestDetail,
        //price_: !priceRequestDetail
      };

      setErrors(newErrors);
    }else if(type == 'send'){

      if (codeRequestReserve.includes('SIM')) {
        newErrors = {
          type_: !typeRequestDetail,
          product_: !productRequestDetail
        };
        
        setErrors(newErrors);
      }else if (typeRequestDetail == 2) {
        newErrors = {
          type_: !typeRequestDetail,
          product_: !productRequestDetail,
          brand_ : !brandRequestDetail,
          model_ : !modelRequest
        };
        
        setErrors(newErrors);
      }else if (productRequestDetail == 3) {
        newErrors = {
          type_: !typeRequestDetail,
          product_: !productRequestDetail,
          ram_ : !ramRequest,
          hard_disc_ : !hardDiscRequest
        };
        
        setErrors(newErrors);
      }else if (productRequestDetail == 11) {
        newErrors = {
          description_: !descriptionRequest
        };
        
        setErrors(newErrors);
      }else{
        newErrors = {
          //description_: !descriptionRequest,
          //company_: !companyRequest,
          //resoruce_: !resourceRequest,
          type_: !typeRequestDetail,
          product_: !productRequestDetail,
          //code_ : !codeRequestReserve,
          //price_: !priceRequestDetail || priceRequestDetail === 0
          processor_: !processorRequest,
          ram_ : !ramRequest,
          hard_disc_ : !hardDiscRequest
        };
        
        setErrors(newErrors);
      }
      
      console.log(newErrors, codeRequestReserve)



    }else if(type == 'apro'){
      
      if (codeRequestReserve.includes('SIM')) {
        newErrors = {
          type_: !typeRequestDetail,
          product_: !productRequestDetail
        };
        
        setErrors(newErrors);
      }else if (typeRequestDetail == 2 /** Sotfware */) {
        newErrors = {
          //description_: !descriptionRequest,
          //company_: !companyRequest,
          //resoruce_: !resourceRequest,
          type_: !typeRequestDetail,
          product_: !productRequestDetail,
          //code_ : !codeRequestReserve,
          price_: !priceRequestDetail || parseFloat(priceRequestDetail) === 0.0,
          //fileOrderBuy_ : !existOrderBuy,
          brand_ : !brandRequestDetail,
          model_ : !modelRequest
        };
        
        setErrors(newErrors);
      }else if (productRequestDetail == 11) {
        newErrors = {
          description_: !descriptionRequest
        };
        
        setErrors(newErrors);
      }else{
        newErrors = {
          //description_: !descriptionRequest,
          //company_: !companyRequest,
          //resoruce_: !resourceRequest,
          type_: !typeRequestDetail,
          product_: !productRequestDetail,
          //code_ : !codeRequestReserve,
          price_: !priceRequestDetail || parseFloat(priceRequestDetail) === 0.0,
          fileOrderBuy_ : !existOrderBuy,
          brand_ : !brandRequestDetail,
          model_ : !modelRequest
        };
        setErrors(newErrors);
      }
    }

    //Vlidacion de perefericos
    /*
    if(productRequestDetail == 11){
      newErrors = {
        description_: !descriptionRequest
      };

      setErrors(newErrors);
    }*/

    const formIsValid = Object.values(newErrors).every(error => !error);
    
    if (formIsValid) {
      setIsShowErrorForm(false);
      //Modicio valores type
      /*
      arrayDetails.forEach(element => {
        let typesDetail = Object.values(listType).find(item => item.label === element.type);
        element.type = typesDetail.value
      });*/
      
      let dataRequest = {
        'id' : idRequest,
        'description' : descriptionRequest,
        'company' : companyRequest,
        'resource' : resourceRequest,
        'type' : type,
        'code' : (typeRequestDetailLabel == 'Hardware')? codeRequestReserve : 'SOF',
        'typeRequest' : typeRequestDetailLabel,
        'product' : (typeRequestDetailLabel == 'Hardware')? productRequestDetail : 10,   
        'price' : priceRequestDetail,
        'brand' : brandRequestDetail,
        'serial_numner' : serialNumberRequestDetail,
        'processor': processorRequest,
        'ghz' : '',
        'ram' : ramRequest,
        'hard_disc' : hardDiscRequest,
        'model' : modelRequest,
        'withSim' : isCheckedSim,
        'codeSim' : codeRequestSim
      };
      
      
      try {
        let response = await AuthApi.saveRequest(dataRequest);
        if (response.data.success === true) {
          
              let message = ""
              let title = ""

              switch (type) {
                case 'draft':
                  message = "La solicitud se ha guardado como borrador."
                  title = "Borrador"
                  break;
                case 'send':
                  message = "La solicitud se ha enviado para continuar el flujo."
                  title = "Solicitud"
                  break;
                case 'apro':
                  if(typeRequestDetailLabel == 'Hardware'){
                    message = "La solicitud se ha enviado para su aprobación."
                    title = "Pendiente Aprobación"
                  }else{
                    message = "La solicitud ha sido Aprobada"
                    title = "Compra Aprobada"
                  }
                  break;
              }
              
              Swal.fire({
                icon: 'success',
                title: title,
                text: message,
                //position: 'top-end',
                showConfirmButton: false,   
                timer: 3000,
                timerProgressBar: true 
              });
              setIsLoadingSaveWait(false)
              clearScreen()

        }
      } catch (err) {
        console.log(err);
      }
      

    } else {
      setIsLoadingSaveWait(false)
      console.log("Hay errores en el formulario");
      setIsShowErrorForm(true);
    }
    
    
  };

  const updateRequest = async () => {

    setIsLoadingSaveWait(true);
    
      let dataRequest = {
        'id' : idRequest,
        'description' : descriptionRequest,
        'company' : companyRequest,
        'resource' : resourceRequest,
        'code' : (typeRequestDetailLabel == 'Hardware')? codeRequestReserve : 'SOF',
        'typeRequest' : typeRequestDetailLabel,
        'product' : (typeRequestDetailLabel == 'Hardware')? productRequestDetail : 10,   
        'price' : priceRequestDetail,
        'brand' : brandRequestDetail,
        'serial_numner' : serialNumberRequestDetail,
        'processor': processorRequest,
        'ghz' : '',
        'ram' : ramRequest,
        'hard_disc' : hardDiscRequest,
        'model' : modelRequest
      };
      
      try {
        let response = await AuthApi.updateRequest(dataRequest);
        if (response.data.success === true) {
          
              
              Swal.fire({
                icon: 'success',
                title: 'Data Actualizada',
                text: 'Se ha actualizado toda la información agregada en los campos',
                //position: 'top-end',
                showConfirmButton: false,   
                timer: 3000,
                timerProgressBar: true 
              });
              setIsLoadingSaveWait(false)
              clearScreen()

        }
      } catch (err) {
        console.log(err);
      }
    
  };
  /*
  const saveRequestAssign = async () => {

    let newErrors = {};

    newErrors = {
      company_: !companyRequest,
      resoruce_: !resourceRequest
    };
    setErrors(newErrors);
    
    const formIsValid = Object.values(newErrors).every(error => !error);
    
    if (formIsValid) {
      
      setIsLoadingSaveWait(true);
      try {

        let dataRequest = {
          'id' : idRequest,
          'company' : companyRequest,
          'resource' : resourceRequest,
          'choice' : 'assign',
          'comment' : '',
        };

        let response = await AuthApi.approveRequest(dataRequest);
        if (response.data.success === true) {
          
              Swal.fire({
                icon: 'success',
                title: "Equipo Asignado",
                text: "El equipo fue asignado al recurso.",
                showConfirmButton: false,   
                timer: 3000,
                timerProgressBar: true 
              });
              setIsLoadingSaveWait(false)
              clearScreen()

        }
      } catch (err) {
        console.log(err);
      }
      
    } else {
      setIsLoadingSaveWait(false)
      console.log("Hay errores en el formulario");
      setIsShowErrorForm(true);
    }
    
  };

  const saveRequestDelivered = async () => {

    let newErrors = {};

    newErrors = {
      company_: !companyRequest,
      resoruce_: !resourceRequest,
      serialNumber_: !serialNumberRequestDetail,
      brand_: !brandRequestDetail
    };
    setErrors(newErrors);
    
    const formIsValid = Object.values(newErrors).every(error => !error);
    
    if (formIsValid) {
      
      setIsLoadingSaveWait(true);
      try {

        let dataRequest = {
          'id' : idRequest,
          'company' : companyRequest,
          'resource' : resourceRequest,
          'serial_numner' : serialNumberRequestDetail,
          'brand' : brandRequestDetail,
          'choice' : 'delivered',
          'date_action' : selectedDate,
          'comment' : '',
        };

        let response = await AuthApi.approveRequest(dataRequest);
        if (response.data.success === true) {
          
              Swal.fire({
                icon: 'success',
                title: "Equipo Entregado",
                text: "El equipo fue entregado al recurso.",
                showConfirmButton: false,   
                timer: 3000,
                timerProgressBar: true 
              });
              setIsLoadingSaveWait(false)
              clearScreen()

        }
      } catch (err) {
        console.log(err);
      }
      
    } else {
      setIsLoadingSaveWait(false)
      console.log("Hay errores en el formulario");
      setIsShowErrorForm(true);
    }
    
  };
  */

  const openModalComment = async (choice) => {
    setCommentDecline('')
    setSelectedDate('')
    setIsModalDeclineOpen(true)

    switch (choice) {
      case 'decline':
        setMessageTextModal('decline')
        setTitleTextModal('Motivo de rechazo')
        setButtonTextModal('Rechazar')
        setCommentDecline('')
        setShowSelectedDate(false)
        break;
      case 'return':
        setMessageTextModal('return')
        setTitleTextModal('Causa de Devolución')
        setTitleDateModal('Fecha de Devolución')
        setButtonTextModal('Devolver')
        setShowSelectedDate(true)
        setShowInputTexarea(true)
        break;
      case 'return_store':
        setMessageTextModal('return_store')
        setTitleTextModal('Causa de Devolución')
        setTitleDateModal('Fecha de Devolución')
        setButtonTextModal('Devolver')
        setShowSelectedDate(true)
        setShowInputTexarea(true)
        break;
      case 'down':
        setMessageTextModal('down')
        setTitleTextModal('Razón para dar de Baja')
        setButtonTextModal('Dar de Baja')
        setShowSelectedDate(false)
        break;
      case 'delivered':
        setMessageTextModal('delivered')
        setTitleTextModal('Comentario de Entrega')
        //setTitleDateModal('Fecha de Entrega')
        setButtonTextModal('Entregar')
        //setShowSelectedDate(true)
        break;
      case 'assign':
        setMessageTextModal('assign')
        setTitleTextModal('Comentario de Asignación')
        setTitleDateModal('Fecha estimada de Entrega')
        setButtonTextModal('Asignar')
        setShowSelectedDate(true)
        setShowInputTexarea(true)
        break;
      case 'fix':
        setMessageTextModal('fix')
        setTitleTextModal('Motivo de Reparación')
        //showSelectedDateButtonTextModal('Reparar')
        setButtonTextModal('Reparar')
        setShowSelectedDate(false)
        setShowInputTexarea(true)
        break; 
      case 'date_final_return':
        setMessageTextModal('date_final_return')
        setTitleDateModal('Fecha final de Devolución')
        //showSelectedDateButtonTextModal('Reparar')
        setButtonTextModal('Fijar Fecha')
        setShowSelectedDate(true)
        setShowInputTexarea(false)
        break; 
        
    }
  };

  const sendApproveRequest = async (choice) => {
    setIsLoadingSaveWait(true);
    
    let dataRequest = {
      'id' : idRequest,
      'comment' : commentDecline,
      'date_action' : selectedDate,
      'choice' : choice,
    };

    let formIsValid = false;
    let formIsValid1 = false;
    let formIsValid2 = false;

    if(choice == 'approve'){
      formIsValid = true;

      let dataRequest = {};

    }else{
      let newErrors = {};

      if(choice == 'assign'){
        if(typeRequestDetail == 2 /* Software */){ 
            //No el numero de serie
            newErrors = {
              company_: !companyRequest,
              resoruce_: !resourceRequest,
              //serialNumber_: !serialNumberRequestDetail,
              brand_: !brandRequestDetail
            };

            let newErrors2 = {
              //comment_: !commentDecline,
              date_: !selectedDate
            };

            setErrorsDecline(newErrors2);
            setErrors(newErrors);
            formIsValid1 = Object.values(newErrors).every(error => !error);
            formIsValid2 = Object.values(newErrors2).every(error => !error);
            formIsValid = formIsValid1 && formIsValid2;

            dataRequest = {
              'id' : idRequest,
              'company' : companyRequest,
              'resource' : resourceRequest,
              'serial_numner' : serialNumberRequestDetail,
              'brand' : brandRequestDetail,
              'choice' : choice,
              'date_action' : selectedDate,
              'comment' : commentDecline,
            };
        }else{
          newErrors = {
            company_: !companyRequest,
            resoruce_: !resourceRequest,
            serialNumber_: !serialNumberRequestDetail,
            brand_: !brandRequestDetail
          };

          let newErrors2 = {
            //comment_: !commentDecline,
            date_: !selectedDate
          };

          setErrorsDecline(newErrors2);
          setErrors(newErrors);
          formIsValid1 = Object.values(newErrors).every(error => !error);
          formIsValid2 = Object.values(newErrors2).every(error => !error);
          formIsValid = formIsValid1 && formIsValid2;

          dataRequest = {
            'id' : idRequest,
            'company' : companyRequest,
            'resource' : resourceRequest,
            'serial_numner' : serialNumberRequestDetail,
            'brand' : brandRequestDetail,
            'choice' : choice,
            'date_action' : selectedDate,
            'comment' : commentDecline,
          };
        }
        

      }else if(choice == 'decline'){
        newErrors = {
          comment_: !commentDecline,
          //date_: !selectedDate
        };

        dataRequest = {
          'id' : idRequest,
          'comment' : commentDecline,
          'date_action' : selectedDate,
          'choice' : choice,
        };

        console.log(newErrors);

        setErrorsDecline(newErrors);
        setErrors(newErrors);
        formIsValid = Object.values(newErrors).every(error => !error);
      
      }else if(choice == 'delivered'){
        if(typeRequestDetail == 2 /* Software */){
          //No es necesaria la hoja de entrega
          newErrors = {
            company_: !companyRequest,
            resoruce_: !resourceRequest
          };

          let newErrors2 = {
            comment_: !commentDecline,
          };

          console.log(newErrors2, newErrors)
          setErrorsDecline(newErrors2);
          setErrors(newErrors);
          formIsValid1 = Object.values(newErrors).every(error => !error);
          formIsValid2 = Object.values(newErrors2).every(error => !error);
          formIsValid = formIsValid1 && formIsValid2;

          dataRequest = {
            'id' : idRequest,
            'company' : companyRequest,
            'resource' : resourceRequest,
            'serial_numner' : serialNumberRequestDetail,
            'brand' : brandRequestDetail,
            'choice' : choice,
            'comment' : commentDecline,
          };
        }else{
          newErrors = {
            company_: !companyRequest,
            resoruce_: !resourceRequest,
            fileDelivery_ : !existFileDelivery
          };

          let newErrors2 = {
            comment_: !commentDecline,
          };

          console.log(newErrors2, newErrors)
          setErrorsDecline(newErrors2);
          setErrors(newErrors);
          formIsValid1 = Object.values(newErrors).every(error => !error);
          formIsValid2 = Object.values(newErrors2).every(error => !error);
          formIsValid = formIsValid1 && formIsValid2;

          dataRequest = {
            'id' : idRequest,
            'company' : companyRequest,
            'resource' : resourceRequest,
            'serial_numner' : serialNumberRequestDetail,
            'brand' : brandRequestDetail,
            'choice' : choice,
            'comment' : commentDecline,
          };
        }
      }else if(choice == 'fix' || choice == 'down'){
        newErrors = {
          comment_: !commentDecline,
          //date_: !selectedDate
        };

        dataRequest = {
          'id' : idRequest,
          'comment' : commentDecline,
          'choice' : choice,
        };

        console.log(newErrors);

        setErrorsDecline(newErrors);
        setErrors(newErrors);
        formIsValid = Object.values(newErrors).every(error => !error);
      
      }else{
        newErrors = {
          comment_: !commentDecline,
          date_: !selectedDate
        };

        dataRequest = {
          'id' : idRequest,
          'comment' : commentDecline,
          'date_action' : selectedDate,
          'choice' : choice,
        };

        console.log(newErrors);

        setErrorsDecline(newErrors);
        setErrors(newErrors);
        formIsValid = Object.values(newErrors).every(error => !error);
      }
    }

    let message = ""
    let title = ""

    switch (choice) {
      case 'received':
        message = 'El equipo se encuentra en las oficinas.'
        title = 'Equipo Recibido'
        break;
      case 'preparing':
        message = "El equipo se encuentra en preparación."
        title = "En Preparación"
        break;
      case 'delivered':
        message = "El equipo fue entregado al recurso."
        title = "Equipo Entregado"
        break;
      case 'assign':
        message = "El equipo fue asignado al recurso."
        title = "Equipo Asignado"
        break;
      case 'return':
        message = "El equipo fue devuelto a ioon."
        title = "Equipo Devuelto"
        break;
      case 'return_store':
        message = "El equipo fue devuelto a tienda."
        title = "Equipo Devuelto"
        break;
      case 'disponible':
        message = "El equipo esta disponible y listo para entrega."
        title = "Equipo Disponible"
        break;
      case 'fix':
        message = "El equipo sera reparado"
        title = "Reparar Equipo"
        break; 
      case 'approve':
        message = "La solicitud se ha aprobado"
        title = "Compra Aprobada"
        break;
      case 'down':
        message = "Equipo dado de baja"
        title = "Equipo de Baja"
        break; 
      case 'decline':
        message = "La solicitud se ha rechazado"
        title = "Compra Rechazada"
        break;
      case 'date_final_return':
        message = "Fecha fina de Devolución establecida"
        title = ""
        break;
    }    

    if(choice == "date_final_return"){
      
      let response = await AuthApi.dateReturnFinal(dataRequest);
        if (response.data.success === true) {

          Swal.fire({
            icon: 'success',
            title: title,
            text: message,
            showConfirmButton: false,   
            timer: 3000,
            timerProgressBar: true 
          });
          setIsLoadingSaveWait(false);
          setIsModalDeclineOpen(false)
        }
    }else{
      if (formIsValid) {
      setIsModalDeclineOpen(false)
      setIsShowErrorForm(false);
      
      try {
        
        
        let response = await AuthApi.approveRequest(dataRequest);
        if (response.data.success === true) {

          Swal.fire({
            icon: (choice == 'decline')? 'error' : 'success',
            title: title,
            text: message,
            showConfirmButton: false,   
            timer: 3000,
            timerProgressBar: true 
          });
          setIsLoadingSaveWait(false);
          clearScreen()

        }
        
        //return setProfile(response);
        
      } catch (err) {
        console.log(err);
      }
      
      } else {
        setIsLoadingSaveWait(false);
        console.log("Hay errores en el formulario");
        setIsShowErrorForm(true);
      }
    }
    
  };

  const checkNextSteep = async (type) => {
    setIsLoadingSaveWait(true);

    let message = ""
    let title = ""

    switch (type) {
      case 'received':
        message = 'El equipo se encuentra en las oficinas.'
        title = 'Equipo Recibido'
        break;
      case 'preparing':
        message = "El equipo se encuentra en preparación."
        title = "En Preparación"
        break;
      case 'delivered':
        message = "El equipo fue entregado al recurso."
        title = "Equipo Entregado"
        break;
      case 'return':
        message = "El equipo fue devuelto a Ioon."
        title = "Equipo Devuelto"
        break;
      case 'disponible':
        message = "El equipo esta disponible y listo para entrega."
        title = "Equipo Disponible"
        break;
      case 'fix':
        message = "El equipo sera reparado"
        title = "Reparar Equipo"
        break; 
      case 'approve':
        message = "La solicitud se ha aprobado"
        title = "Compra Aprobada"
        break; 
    }

    let dataRequest = {
      'id' : idRequest,
      'comment' : '',
      'choice' : type,
    };

    try {
      let response = await AuthApi.approveRequest(dataRequest);
      if (response.data.success === true) {
        Swal.fire({
          icon: 'success',
          title: title,
          text: message,
          showConfirmButton: false,   
          timer: 3000,
          timerProgressBar: true 
        });
        setIsLoadingSaveWait(false);
        clearScreen()
      }
      return setProfile(response);
    } catch (err) {
      console.log(err);
    }
  };

  const createRequest =  () => {
    clearScreen()
  };

  return (
    <Flex direction="row" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ sm: "1fr", lg: "5fr 1fr" }} gap={6}>
        <Grid templateRows="auto auto" gap={4} alignItems="start"  mt={15}>
          <Box margin={0} padding={0}>
          <Card my={{ lg: "24px" }} me={{ lg: "24px" }} w={{ base: "100%", lg: "100%" }}>
              <Flex direction="column">
                <CardHeader py="12px">
                  <Text color={textColor} fontSize="lg" fontWeight="bold">
                    {titlePage}
                  </Text>
                  <Flex  direction="row" w="80%" wrap="wrap" justifyContent="end">
                    {(Functions.canDoit(1) /** Crear Solicitar */) && (
                      <Button bg="green.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => createRequest()}>
                        {isLoadingSaveWait ? <Spinner size="xs" /> : 'Nueva Solicitud'}
                      </Button>
                    )}
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Flex direction="column" w="100%">
                  {(isShowSelectRequest) && (
                    <Flex direction="row" w="100%">
                      <Flex direction="column" w="100%">
                        {isLoadingRequestList ? (
                          <Spinner size="md" color="teal.300" />
                        ) : (
                          <Select
                            components={{ DropdownIndicator }}
                            styles={customStyles}
                            value={selectedOptionSearch}
                            onChange={handleChangeSelectRequest}
                            options={listRequest}
                            isClearable={true}
                            placeholder="Seleccione solicitud o escriba solicitud..."
                            isSearchable={true}
                          />
                        )}
                      </Flex>
                    </Flex>
                  )}
                  </Flex>
                </CardBody>
              </Flex>
            </Card>
          </Box>
          <Box padding={0} mt={0} >
            <Card  mt={marginTopPageRequest}  w={{ base: "100%", lg: "100%" }}>
              <Flex direction="column">
                <CardHeader py="12px">
                  <Flex direction="row" w="100%" mb="8" alignItems="center" justifyContent="space-between">
                    <Text color={textColor} fontSize="lg" fontWeight="bold">
                      {titleSectionRequest}
                    </Text>
                    {isLoadingRequestCurrent ? (
                        <Spinner size="md" color="teal.300" />
                      ) : (
                        <></>
                      )}
                    <Flex  direction="row" w="80%" wrap="wrap" justifyContent="end">
                      
                      {(Functions.canDoit(3) /** Aprobar / Rechazar Compra */ && statusRequest == 3 /** Pendiente Aprobación */) && (
                          <Button bg="green.500" color="white" fontSize="xs" p="8px 32px" m={2} leftIcon={<AiOutlineCheck />} onClick={() => sendApproveRequest('approve')}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Aprobar'}
                          </Button>
                      )}
                      {(Functions.canDoit(3) /** Aprobar / Rechazar Compra */ && statusRequest == 3 /** Pendiente Aprobación */) && (
                          <Button bg="red.500" color="white" fontSize="xs" p="8px 32px" m={2} leftIcon={<AiOutlineCloseCircle />} onClick={() => openModalComment('decline')}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Rechazar'}
                          </Button>
                      )}
                      {(Functions.canDoit(6) /** Recibir / Devolver Equipo */ && statusRequest == 4 /** Compra Aprobada */) && (
                        <>
                          <Button bg="green.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => checkNextSteep('received')}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Recibir Equipo'}
                          </Button>
                          <Button bg="red.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => openModalComment('return_store') /*checkNextSteep('return')*/}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Devolver a Tienda'}
                          </Button>
                        </>
                      )}
                      {(Functions.canDoit(7) /** Preparar Equipo */ && (statusRequest == 6 /** Equipo Recibido */)) && (
                        <Button bg="green.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => checkNextSteep('preparing')}>
                          {isLoadingSaveWait ? <Spinner size="xs" /> : 'Marcar En Preparación'}
                        </Button>
                      )}
                      {((Functions.canDoit(6) /** Recibir / Devolver Equipo */) && (statusRequest == 11 /** Devuelto */ )) && (
                          <Button bg="green.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => checkNextSteep('received')}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Recibir Equipo'}
                          </Button>
                      )}
                      {((Functions.canDoit(7) /** En Preparación */) && (statusRequest == 7 /** En Preparación*/ || statusRequest == 6 /** Equipo Recibido */ || statusRequest == 12 /** En reparación */ )) && (
                        <Button bg="green.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => checkNextSteep('disponible')}>
                          {isLoadingSaveWait ? <Spinner size="xs" /> : 'Marcar como Disponible'}
                        </Button>
                      )}
                      {((Functions.canDoit(9)  /** Asignar Equipo */) && statusRequest == 8 /** Equipo Disponible */) && (
                        <Button bg="orange.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => openModalComment('assign') /*saveRequestAssign() checkNextSteep('delivered')*/}>
                          {isLoadingSaveWait ? <Spinner size="xs" /> : 'Asignar Equipo'}
                        </Button>
                      )}
                      {((Functions.canDoit(10)  /** Entregar Equipo */) && statusRequest == 9 /** Equipo Disponible */) && (
                        <Button bg="orange.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => openModalComment('delivered') /*saveRequestDelivered()*/ /*checkNextSteep('delivered')*/}>
                          {isLoadingSaveWait ? <Spinner size="xs" /> : 'Entregar Equipo'}
                        </Button>
                      )}
                      {((Functions.canDoit(6)  /** Recibir / Devolver Equipo */) && statusRequest == 10 /** Entregado / Asignado */) && (
                        <>
                          <Button bg="red.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => openModalComment('return') /*checkNextSteep('return')*/}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Devolver Equipo'}
                          </Button>
                          <Button bg="red.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => openModalComment('date_final_return') /*checkNextSteep('return')*/}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Fecha Final de Devolución'}
                          </Button>
                        </>
                      )}
                      {((Functions.canDoit(8)  /** Reparar Equipo */) && statusRequest == 11 /** Equipo Devuelto */) && (
                        <Button bg="red.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => openModalComment('fix') /*checkNextSteep('fix')*/}>
                          {isLoadingSaveWait ? <Spinner size="xs" /> : 'Reparar Equipo'}
                        </Button>
                      )}
                      {((Functions.canDoit(8)  /** Reparar Equipo */) && (statusRequest == 12 /** En Reparación */ || statusRequest == 11 /**Equipo Devuelto */)) && (
                        <Button bg="red.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => openModalComment('down') /*checkNextSteep('fix')*/}>
                          {isLoadingSaveWait ? <Spinner size="xs" /> : 'Dar de Baja'}
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Flex direction="column" w="100%">
                    {/*<Flex direction="row" w="100%">
                       <Flex direction="column" w="100%" mr="8">
                          <InputText label={'Descripción de Solicitud'} placeholder={'Ingrese descripción'} defaultValue={nameRequest} error={errors.name_} captureValue={handleCaptureValueName} />
                        </Flex>
                        <Flex direction="column" w="100%">
                          <InputNotBorder label={'Total'} placeholder={''} defaultValue={totalRequest} error={null} captureValue={handleCaptureValueTotal}/>
                        </Flex>
                    </Flex>*/}
                    {isShowErrorForm ? (
                      <Flex direction="row" w="100%">
                        <Flex direction="column" w="100%" mr="8" mb="10">
                          <Text color="red.500" fontSize="15px" fontWeight="normal">
                              {"Revisa todo formulario y los posibles campos o archivos obligatorios..."}
                          </Text>
                        </Flex>
                      </Flex>
                      ) : (
                        null
                    )}
                    <Flex direction="row" w="100%">
                      <Flex direction="column" w="100%" mr="8">
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          {"Tipo"}
                        </FormLabel>
                        <Select
                          styles={customStylesSelectModal(errors.type_)}
                          value={selectedOptionType}
                          onChange={handleCaptureValueDetailType}
                          options={listType}
                          isDisabled={isSelectTypeDisabled}
                          placeholder="Seleccione tipo"
                        />
                      </Flex>
                      <Flex direction="column" w="100%">
                          {isLoadingProducs ? (
                            <Spinner size="md" color="teal.300" mb={5} mt={5}  />
                          ) : (
                          <>
                            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                              {"Producto"}
                            </FormLabel>
                            <Select
                                styles={customStylesSelectModal(errors.product_)}
                                value={selectedOptionProduct}
                                onChange={handleCaptureValueDetailProduct}
                                options={listProduct}
                                isDisabled={isSelectProductDisabled}
                                placeholder="Seleccione producto"
                              />
                          </>
                        )}
                      </Flex>
                    </Flex>
                    {isShowInputWhitSIM ? (
                        <>
                          <Flex direction="row" w="100%">
                            <Flex direction="column" w="100%" mr="8">
                            </Flex>
                            <Flex direction="column" w="100%">
                              <label>
                                <input type="checkbox" checked={isCheckedSim} onChange={handleCheckboxChangeSim} />
                                <>&nbsp;&nbsp;&nbsp;&nbsp;</>Incluir tarjeta SIM
                              </label>
                            </Flex>
                          </Flex>
                        </>
                      ) : (
                        null
                    )}
                    <Flex direction="row" w="100%">
                      {/*<Flex direction="column" w="100%" mr="8">
                          {isLoadingCode ? (
                              <Spinner size="md" color="teal.300" mb={5} mt={5} />
                            ) : (
                            <InputText label={'Código'} placeholder={'Generado automaticamente'} defaultValue={codeRequestDetail} error={errors.code_} reandoly={true} captureValue={handleCaptureValueDetailCode} />
                          )}
                      </Flex>*/}
                      <Flex direction="column" w="100%" mr="8">
                          <InputText label={'Código'} placeholder={'Generado automaticamente'} defaultValue={codeRequestDetail} error={errors.code_} reandoly={true} captureValue={handleCaptureValueDetailCode} />
                      </Flex>
                      <Flex direction="column" w="100%">
                        <InputAmount label={'Precio'} placeholder={'Ingrese Precio'} defaultValue={priceRequestDetail} error={errors.price_} captureValue={handleCaptureValueDetailPrice} /> 
                      </Flex>
                    </Flex>
                    <Flex direction="row" w="100%">
                      <Flex direction="column" w="100%">
                        <InputTexarea label={'Especificación Técnica / Descripción'} placeholder={'Ingrese especificaciones'} defaultValue={descriptionRequest}  error={errors.description_} captureValue={handleCaptureValueDescription} />
                      </Flex>
                    </Flex>
                    <Flex direction="row" w="100%">
                      <Flex direction="column" w="100%" mr="8">
                        <InputText label={'Marca'} placeholder={'Ingrese marca'} defaultValue={brandRequestDetail} error={errors.brand_} captureValue={handleCaptureValueDetailBrand} /> 
                      </Flex>
                      <Flex direction="column" w="100%">
                        <InputText label={'Número de Serie'} placeholder={'Ingrese número'} defaultValue={serialNumberRequestDetail} error={errors.serialNumber_} captureValue={handleCaptureValueDetailSerial} /> 
                      </Flex>
                    </Flex>
                    <Flex direction="row" w="100%">
                      <Flex direction="column" w="100%" mr="8">
                        <InputText label={'Modelo'} placeholder={placeHoderModel} defaultValue={modelRequest} error={errors.model_} captureValue={handleCaptureValueModel} /> 
                      </Flex>
                      {isShowInputProcesadorRequest ? (
                            <Flex direction="column" w="100%">
                              <InputText label={'Procesador'} placeholder={'i5'} defaultValue={processorRequest} error={errors.processor_} captureValue={handleCaptureValueProcessor} /> 
                            </Flex>
                          ) : (
                          <></>
                        )}
                    </Flex>
                    <Flex direction="row" w="100%">
                        {isShowInputRamRequest ? (
                            <Flex direction="column" w="100%" mr="8">
                              <InputText label={'Memoria RAM (GB)'} placeholder={'16 GB'} defaultValue={ramRequest} error={errors.ram_} captureValue={handleCaptureValueRam} /> 
                            </Flex>
                          ) : (
                          <></>
                        )}
                        {isShowInputHardDiscRequest ? (
                            <Flex direction="column" w="100%">
                              <InputText label={'Disco Duro (GB)'} placeholder={'512 GB'} defaultValue={hardDiscRequest} error={errors.hard_disc_} captureValue={handleCaptureValueHardDisc} /> 
                            </Flex>
                          ) : (
                          <></>
                        )}
                    </Flex>
                    <Flex direction="row" w="100%">
                      <Flex direction="column" w="100%" mr="8">
                        <InputText label={'Cliente'} placeholder={'Ingrese compañia / empresa'} defaultValue={companyRequest}  error={errors.company_} captureValue={handleCaptureValueCompny} />
                      </Flex>
                      <Flex direction="column" w="100%">
                        <InputText label={'Usuario Final'} placeholder={'Ingrese recurso'} defaultValue={resourceRequest}  error={errors.resoruce_} captureValue={handleCaptureValueResource}/>
                      </Flex>
                    </Flex>
                    {/*
                    <Flex direction="row" w="100%" mb="8" alignItems="center" justifyContent="end">
                      {((Functions.canDoit(1) ) && (statusRequest == null || statusRequest == 1  || statusRequest == 2 )) && (
                        <Flex>
                          <Button bg="green.500" color="white" fontSize="xs" p="8px 32px" mr="5" leftIcon={<AiOutlinePlus />}  onClick={() => addDetail()}>
                            Agregar Detalles
                          </Button>
                        </Flex>
                      )}
                    </Flex>*/}
                    
                    {/*
                    <Flex direction="row" w="100%" mb="8">
                      <Table variant="simple" color={textColor}>
                        <Thead>
                          <Tr my=".8rem" pl="0px" color="gray.500">
                            <Th pl="0px" color="gray.500">ITEM</Th>
                            <Th pl="0px" color="gray.500">CÓDIGO</Th>
                            <Th pl="0px" color="gray.500">DESCRIPCIÓN</Th>
                            <Th color="gray.500">TIPO</Th>
                            <Th color="gray.500">CANTIDAD</Th>
                            <Th color="gray.500">PRECIO</Th>
                            <Th color="gray.500">TOTAL</Th>
                            {((userDataProfile.role_id == 4  || userDataProfile.role_id == 7 ) && (statusRequest == null || statusRequest == 1  || statusRequest == 2 )) && (
                              <Th></Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {arrayDetails.map((row, index) => {
                            return (
                              <RowRequestDetail
                                key={index}
                                item={row.item} 
                                code={row.code} 
                                description={row.description} 
                                type={row.type}
                                quantity={row.quantity} 
                                price={row.price} 
                                total={row.total}
                                statusRequest={statusRequest}
                                onEditarClick={handleEditDetail}
                                onEliminarClick={handleDeleteDetail}
                              />
                            );
                          })}
                        </Tbody>
                      </Table>
                    </Flex>*/ }

                    <Flex direction="row" w="100%" mb="8" alignItems="center" justifyContent="space-between">
                      <Flex>

                        {((Functions.canDoit(1)  /** Crear Solicitar */) && (statusRequest == null || statusRequest == 1 /** Borrador */ || statusRequest == 5 /** Compra Rechazada */)) && (
                          <Button bg="gray.500" color="white" fontSize="xs" p="8px 32px" mr="5" onClick={() => saveRequest('draft')}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Guardar Borrador'}
                          </Button>
                        )}
                        {((Functions.canDoit(1)  /** Crear Solicitar */) && (statusRequest == null || statusRequest == 1 /** Borrador */ || statusRequest == 5 /** Compra Rechazada */)) && (
                          <Button bg="orange.500" color="white" fontSize="xs" p="8px 32px" mr="5" onClick={() => saveRequest('send')}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Solicitar'}
                          </Button>
                        )}
                        {((Functions.canDoit(1)  /** Crear Solicitar */) && (statusRequest == 2 /** Solicitud */)) && (
                          <Button bg="orange.500" color="white" fontSize="xs" p="8px 32px" mr="5" onClick={() => saveRequest('send')}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Guardar'}
                          </Button>
                        )}
                        {((Functions.canDoit(2)  /** Enviar solicitud a Flujo de Compra */) && (statusRequest == 2 /** Solicitud */)) && (
                          <Button bg="red.500" color="white" fontSize="xs" p="8px 32px" mr="5" onClick={() => handleDownload()}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Descargar Formato de OC'}
                          </Button>
                        )}
                        {((Functions.canDoit(2)  /** Enviar solicitud a Flujo de Compra */) && (statusRequest == 2 /** Solicitud */)) && (
                          <Button bg="blue.500" color="white" fontSize="xs" p="8px 32px" mr="5" onClick={() => saveRequest('apro')}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Solicitar Aprobación'}
                          </Button>
                        )}
                        {((Functions.canDoit(6)  /** Recibir / Devolver Equipo */) && statusRequest == 9 /** Asignado */) && (
                          <GeneratePDFDelivery name={resourceRequest} items={dataDetail} />
                        )}
                        {((Functions.canDoit(6)  /** Recibir / Devolver Equipo */) && statusRequest == 10 /** Entregado */) && (
                          <GeneratePDFReturn name={resourceRequest} items={dataDetail} />
                        )}
                        {(Functions.canDoit(4) /**Cargar Archivos */ && statusRequest != null) && (
                          <Flex>
                            <InputFileButton isLoadingSaveWait={isLoadingSaveWait} captureValue={handleCaptureValueFile} />
                          </Flex>
                        )}
                        {(Functions.canDoit(11)  /** Administar */) && (
                          <Button bg="blue.500" color="white" fontSize="xs" p="8px 32px" mr="5" onClick={() => updateRequest('update')}>
                            {isLoadingSaveWait ? <Spinner size="xs" /> : 'Actualizar Datos'}
                          </Button>
                        )}
                      </Flex>
                    </Flex>
                    <Flex direction="row" w="100%" mb="8" alignItems="center" justifyContent="end">
                      <Flex direction="column">
                        <Flex direction="column" w="100%" mr="8">
                        <Flex direction="row" w="100%">
                          {errors.fileOrderBuy_ && (
                              <Text color="red.500" fontSize="10px" fontWeight="normal">
                                {"Debe agregar el archivo de Orden de Compra"}
                              </Text>
                            )}
                        </Flex>
                        <Flex direction="row" w="100%">
                          {errors.fileDelivery_ && (
                              <Text color="red.500" fontSize="10px" fontWeight="normal">
                                {"Debe agregar el archivo de Entrega de Equipo"}
                              </Text>
                            )}
                        </Flex>
                        
                          <Text color={textColor} fontSize="sm" fontWeight="bold">
                            {"Archivos Adjuntos"}
                          </Text>
                        </Flex>
                        <Flex direction="column" w="100%" mr="8">
                          <ul style={ulStyle}>
                            {listFiles.map((item, index) => (
                              <li key={index}>
                                <a href={item.path} target="_blank"  style={aStyle} rel="noopener noreferrer">
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </CardBody>
              </Flex>
            </Card>
          </Box>
        </Grid>
        <Box>
          <Card my={{ lg: "24px" }} me={{ lg: "24px" }} w={{ base: "100%", lg: "100%" }}>
            <CardHeader mb="12px">
              <Flex direction="column" w="100%">
                <Flex
                  direction={{ sm: "column", lg: "row" }}
                  justify={{ sm: "center", lg: "space-between" }}
                  align={{ sm: "center" }}
                  w="100%"
                  my={{ md: "12px" }}
                >
                  <Text
                    color={textColor}
                    fontSize={{ sm: "lg", md: "xl", lg: "lg" }}
                    fontWeight="bold"
                  >
                    Estados
                  </Text>
                </Flex>
              </Flex>
            </CardHeader>
            <CardBody>
              <Flex direction="column" w="100%">
                  <TimelineStatus idPurchaseRequest={idRequest}/>
              </Flex>
            </CardBody>
          </Card>
        </Box>
      </Grid>
      {/*
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Formulario de Solicitud"
        ariaHideApp={false}
        style={customStylesModal}
      >
        <Flex direction="column" p="20px">
          <CardHeader py="12px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Detalle de Solicitud
            </Text>
          </CardHeader>
          <CardBody>
            <Flex direction="column" w="100%">
              <Flex direction="column" w="100%" mr="8">
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  {"Tipo"}
                </FormLabel>
                <Select
                  styles={customStylesSelectModal(errors.type_)}
                  value={selectedOptionType}
                  onChange={handleCaptureValueDetailType}
                  options={listType}
                  placeholder="Seleccione tipo"
                />
              </Flex>
              {(isShowSelectProductRequest) && (
                <>               
                  <Flex direction="column" w="100%" mr="8">
                    {isLoadingProducs ? (
                      <Spinner size="md" color="teal.300" mb={5} mt={5}  />
                    ) : (
                    <>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        {"Producto"}
                      </FormLabel>
                      <Select
                          styles={customStylesSelectModal(errors.product_)}
                          value={selectedOptionProduct}
                          onChange={handleCaptureValueDetailProduct}
                          options={listProduct}
                          placeholder="Seleccione producto"
                        />
                    </>
                  )}
                  </Flex>
                  <Flex direction="column" w="100%" mr="8">
                    {isLoadingCode ? (
                        <Spinner size="md" color="teal.300" mb={5} mt={5} />
                      ) : (
                      <InputText label={'Código'} placeholder={'Ingrese Código'} defaultValue={codeRequestDetail} error={errors.code_} reandoly={true} captureValue={handleCaptureValueDetailCode} />
                    )}
                  </Flex>
                </>
              )}
              
              <Flex direction="column" w="100%" mr="8">
                <InputText label={'Descripción / Equipo'} placeholder={'Ingrese Descripción'} defaultValue={descriptionRequestDetail} error={errorsDetail.descriptionDetail_} captureValue={handleCaptureValueDetailDescription} />
              </Flex>
              <Flex direction="column" w="100%" mr="8">
                <InputNumber label={'Cantidad'} placeholder={'Ingrese Cantidad'} defaultValue={quantityRequestDetail} error={errorsDetail.quantity_} captureValue={handleCaptureValueDetailQuantity} /> 
              </Flex>
              <Flex direction="column" w="100%" mr="8">
                <InputAmount label={'Precio'} placeholder={'Ingrese Precio'} defaultValue={priceRequestDetail} error={errors.price_} captureValue={handleCaptureValueDetailPrice} /> 
              </Flex>
              <Flex direction="column" w="100%" mr="8">
                <Button  bg="green.500" color="white" mt={4} onClick={() => saveDetail()}>Guardar</Button>
              </Flex>
            </Flex>
          </CardBody>
        </Flex>
      </Modal>
      */}
      <Modal
        isOpen={isModalDeclineOpen}
        onRequestClose={() => setIsModalDeclineOpen(false)}
        contentLabel="Formulario de Comentario"
        ariaHideApp={false}
        style={customStylesModal}
      >
        <Flex direction="column" p="20px">
          <CardHeader py="12px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              
            </Text>
          </CardHeader>
          <CardBody>
            <Flex direction="column" w="100%">
              {showInputTexarea ?
              <Flex direction="column" w="100%">
                <InputTexarea label={titleTextModal} placeholder={'Ingrese comentario'} defaultValue={commentDecline}  error={errorsDecline.comment_} captureValue={handleCaptureValueCommentDecline} />
              </Flex> : null 
               }
               {showSelectedDate ? 
               
               <Flex direction="column" w="100%">
                <InputDate label={titleDateModal} defaultValue={selectedDate} error={errorsDecline.date_} captureValue={handleDateChange} />
               </Flex> : null 
               }
              <Flex direction="column" w="100%" mr="8">
                <Button  bg="red.500" color="white" mt={4} onClick={() => sendApproveRequest(messageTextModal)}>{buttonTextModal}</Button>
              </Flex>
            </Flex>
          </CardBody>
        </Flex>
      </Modal>
    </Flex>
  );
}

export default PurchaseRequest;
